/* eslint-disable no-undef */
//function names must be these due IOs integration

function handleFacebookShareIOS(mediaArray, postDescription) {
  try {
    webkit.messageHandlers.facebook.postMessage({
      url: mediaArray,
      copy: postDescription,
    })
  } catch (err) {
    console.log('error on facebook sharing')
  }
}

function handleInstagramShareIOS(mediaArray, postDescription) {
  try {
    webkit.messageHandlers.instagram.postMessage({
      url: mediaArray,
      copy: postDescription,
    })
  } catch (err) {
    console.log('error on instagram sharing')
  }
}

function sendDownloadEvent(mediaArray) {
  try {
    webkit.messageHandlers.download.postMessage({
      url: mediaArray,
    })
  } catch (err) {
    console.log('error on downloading')
  }
}

function handleLeaveWebview() {
  try {
    webkit.messageHandlers.exit.postMessage({})
  } catch (err) {
    console.log('error on exiting')
  }
}

function signalAppUserEnteringForMe() {
  try {
    webkit.messageHandlers.meMarketingRequest.postMessage({})
  } catch (err) {
    console.log('error on selecting for me flow')
  }
}

function signalAppToHideBackButton() {
  try {
    webkit.messageHandlers.hideBackButton.postMessage({})
  } catch (err) {
    console.log('error on hiding back button')
  }
}

function signalAppToShowBackButton() {
  try {
    webkit.messageHandlers.enableBackButton.postMessage({})
  } catch (err) {
    console.log('error on signaling app to show back button')
  }
}

function signalNonAuthed() {
  try {
    webkit.messageHandlers.nonAuth.postMessage({})
  } catch (err) {
    console.log('error on calling app unauthed')
  }
}

function presentActionSheet(options, cb) {
  try {
    window.av8.actionSheet = (actionID) => {
      if (cb) {
        cb(actionID)
        window.av8.actionSheetAction = () => {}
      }
    }

    webkit.messageHandlers.presentActionSheet.postMessage({
      options,
      callbackName: 'actionSheet',
    })
  } catch (err) {
    console.error(err)
  }
}

function sendUserToAppHomeScreen() {
  try {
    webkit.messageHandlers.backHome.postMessage({})
  } catch (err) {
    console.log('error on calling app unauthed')
  }
}

function downloadCSVFiles(urlsArray) {
  try {
    webkit.messageHandlers.CSV.postMessage({
      url: urlsArray,
    })
  } catch (err) {
    console.log('error on downloading csv files')
  }
}

function downloadZIPFile(urlsArray) {
  try {
    webkit.messageHandlers.ZIP.postMessage({
      url: urlsArray,
    })
  } catch (err) {
    console.log('error on downloading csv files')
  }
}


export {
  handleFacebookShareIOS,
  handleInstagramShareIOS,
  sendDownloadEvent,
  handleLeaveWebview,
  signalAppUserEnteringForMe,
  signalAppToHideBackButton,
  signalAppToShowBackButton,
  signalNonAuthed,
  presentActionSheet,
  sendUserToAppHomeScreen,
  downloadCSVFiles,
  downloadZIPFile,
}
