import JSZip from 'jszip'
import { triggerDownload } from '../../../utils/utils'

export const zipAndDownloadFiles = async (files: { filename: string; blob: Blob }[], folderName: string, fileNamePrefix = '', zipName = 'avenue8_files') => {
  const zip = new JSZip()
  const folder = zip.folder(folderName)
  if (!folder) {
    throw new Error('failed to create zip folder')
  }
  files.forEach((file, idx) => {
    const fileName = `${fileNamePrefix}_${idx + 1}_${file.filename}`
    folder.file(fileName, file.blob)
  })
  const content = await zip.generateAsync({type: 'blob'})
  return triggerDownload(content, `${zipName}.zip`)
}
