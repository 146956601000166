import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, makeStyles } from '@material-ui/core'
import Text from '../components/common/Text'
import InformationInput from '../components/custom/InformationInput'
import PagePresentation from '../components/common/PageTitle'
import CustomGridContainer from '../components/common/CustomGridContainer'
import Layout from '../components/common/Layout.js'
import { useAuthContext } from '../context/AuthContext'
import { useRequestContext } from '../context/RequestContext'
import SignReviewPresentation from '../modules/signage/components/ReviewRequest/SignReviewPresentation'
import OptionsPresentation from '../components/common/OptionsPresentation'
import MarketingRequestReceivedPresentation from '../components/custom/MarketingRequest/RequestReceivedPresentation'
import { signalAppToHideBackButton } from '../services/appServices'
import useHistoryPrefix from '../hooks/useHistoryPrefix'
import { useSignageContext } from '../context/SignageContext'
import { generateDataAttrs } from '../utils/utils'
import {
  fromMoney,
  toMoney,
} from '../components/custom/DynamicForm/formatters/money.formatter'

const styles = (theme) => ({
  image: { width: '300px', height: '300px' },
  container: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  upperAreaContainer: {
    padding: '0 0 40px 0',
    borderBottom: '1px solid rgba(42, 41, 166, 0.35)',
    marginBottom: 20,
  },
})

export default function RequestReceived({ location }) {
  const { requestTypePath } = useParams()
  const history = useHistoryPrefix()

  const [showPage, setShowPage] = useState(false)
  const [pageData, setPageData] = useState({
    request: {},
    signs: [],
    options: {},
  })
  const [pageLabels, setPageLabels] = useState({
    costLabel: 'Cost estimate',
    addressLabel: 'Installation address',
  })
  const { setSelectedPropertyCtx, isEmbedded } = useRequestContext()
  const { dataToBePresented } = useSignageContext()
  const { agentId } = useAuthContext()
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  useEffect(() => {
    setShowPage(true)
    setPageData(selectPageData(requestTypePath))
    setPageLabels(selectLabels(requestTypePath))
    setSelectedPropertyCtx('')
    signalAppToHideBackButton()
  }, [])

  const selectPageData = (requestTypePath) => {
    switch (requestTypePath) {
      case 'new-installation':
      case 'removal':
      case 're-installation':
        return {
          ...pageData,
          request: location.state.data,
          signs: dataToBePresented,
          options: location.state.options,
        }
      case 'forme':
        return {
          ...pageData,
          request: location.state.data,
        }
      case 'digital':
      case 'digitalprint':
        return {
          ...pageData,
          request: location.state.data,
          options: location.state.options,
        }
      default:
        return {}
    }
  }

  const handeRequestClick = () => {
    history.push(`/signage?mbd=${isEmbedded}&agentId=${agentId}`)
  }

  const selectLabels = (requestTypePath) => {
    switch (requestTypePath) {
      case 'digital':
      case 'digitalprint':
        return {
          costLabel: 'Total Cost (excluding print-related costs)',
          addressLabel: 'Listing',
          confirmationAndPricingMessage:
            "You'll receive an email confirming your request and final price. You can track the status of your marketing order in the app.",
        }
      case 'forme':
        return {
          confirmationAndPricingMessage:
            "You'll receive an email confirming your request. You can track the status of your marketing order in the app. Avenue 8 will invoice you your request.",
        }
      default:
        return {
          costLabel: 'Cost estimate',
          addressLabel: 'Installation address',
          confirmationAndPricingMessage:
            "You'll receive an email confirming your request and final price.",
        }
    }
  }

  return (
    <Layout>
      {showPage && pageData && (
        <CustomGridContainer>
          <PagePresentation
            CTATextUpper={'Request'}
            CTATextLower={'Received!'}
            component={'span'}
            isSingleLine
          />
          <Grid className={classes.container}>
            <Grid className={classes.upperAreaContainer}>
              <Text
                type='medium'
                style={{ fontSize: 12, marginBottom: 10 }}
                {...generateDataAttrs({
                  metaName: 'order',
                })}
              >
                <span>Order</span>
                &nbsp;
                <span {...generateDataAttrs({ metaName: 'order-id' })}>
                  {pageData.request.orderId}
                </span>
              </Text>
              <Text type='medium' style={{ marginBottom: 20 }}>
                {pageLabels.confirmationAndPricingMessage}
              </Text>
              {pageData?.request?.sentTo && (
                <InformationInput
                  label='Confirmation sent to:'
                  information={pageData.request.sentTo}
                />
              )}
              {pageData.request.totalEstimatedCost && (
                <InformationInput
                  label={pageLabels.costLabel}
                  information={pageData.request.totalEstimatedCost
                    .split('-', 2)
                    .map((cost) =>
                      toMoney(fromMoney(cost.trim().replace(/\.\d+$/, ''))),
                    )
                    .join(' - ')}
                />
              )}
              {pageData?.request?.description && (
                <InformationInput
                  label={'Description'}
                  information={pageData.request.description}
                />
              )}
              <InformationInput
                label={pageLabels.addressLabel}
                information={pageData.request.listingAddress}
              />
            </Grid>
            {requestTypePath === 'new-installation' &&
              pageData.signs.map((sign, index) => {
                return (
                  <SignReviewPresentation
                    sign={sign.presentationData}
                    index={index}
                    key={sign.signId}
                  />
                )
              })}
            {(requestTypePath === 'removal' ||
              requestTypePath === 're-installation') && (
              <OptionsPresentation options={pageData.options} />
            )}
            {(requestTypePath === 'digital' ||
              requestTypePath === 'digitalprint') && (
              <MarketingRequestReceivedPresentation
                handleRequestSignageclick={handeRequestClick}
              />
            )}
          </Grid>
        </CustomGridContainer>
      )}
    </Layout>
  )
}
