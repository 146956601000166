import React, { useState, useEffect } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DynamicFormGenerator from '../DynamicFormGenerator'
import { getCostEstimate } from '../../../services/signageServices'
import InformationInput from '../../custom/InformationInput'
import Loading from '../../common/Loading'
import { useSignageContext } from '../../../context/SignageContext'
import { useRequestContext } from '../../../context/RequestContext'

export default function ExistingSignageReview({
  options,
  formData,
  setFormData,
}) {
  const styles = () => ({
    container: {
      borderTop: '1px solid rgba(42, 41, 166, 0.35)',
      marginTop: '20px',
      padding: '40px 0 0 0',
    },
  })
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const { selectedPropertyCtx, listingAddressCtx } = useRequestContext()
  const { installationType } = useSignageContext()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getCost = async () => {
      const response = await getCostEstimate(
        listingAddressCtx.city,
        undefined,
        installationType,
      )
      setFormData({
        ...formData,
        costEstimate: {
          value: response.data.estimatedCost,
          optionLabel: 'Cost estimate',
        },
      })
      setLoading(false)
    }
    getCost()
  }, [])

  return (
    <Grid className={classes.container}>
      {loading ? (
        <Loading heightVh="30vh" />
      ) : (
        <Grid container direction="column" style={{ marginBottom: 15 }}>
          {formData.costEstimate && (
            <InformationInput
              label="Cost estimate"
              information={formData?.costEstimate.value}
            />
          )}
          {formData.costEstimate && (
            <InformationInput
              label="Installation Address"
              information={selectedPropertyCtx}
            />
          )}
          <DynamicFormGenerator
            options={options}
            formData={formData}
            setFormData={setFormData}
          />
        </Grid>
      )}
    </Grid>
  )
}
