import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import Text from '../../../components/common/Text'
import { makeStyles } from '@material-ui/styles'
import Dialog from '@material-ui/core/Dialog'
import DynamicForm from '../../../modules/signage/components/DynamicForm/Form'
import Button from '../../../components/common/Button'
import Loading from '../../../components/common/Loading'
import CostEstimateField from '../../../components/custom/Signage/CostEstimateField'
import ErrorMsg from '../../../components/common/ErrorMsg'
import { getSignOptions } from '../../../services/signageServices'
import { useSignageContext } from '../../../context/SignageContext'
import { useRequestContext } from '../../../context/RequestContext'
import { useSignageStore } from '../../../modules/signage/store/signage.store'
import {
  formatArrayOfOptions,
  formatFormDataIntoPresentationData,
  formatFormDataIntoRequestData,
  validateForm,
} from '../../../utils/dynamicForm'
import { FormDataType, SignCategoryType, SignType } from '../types'

const styles = {
  container: {
    padding: '15px 20px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    marginTop: '12px',
  },
  media: {
    width: '100%',
    marginTop: 7,
    marginBottom: 15,
  },
  valueText: {
    fontSize: '14px',
    fontFamily: 'StyreneMedium',
    color: 'var(--blue)',
    letterSpacing: '0.25px',
    margin: '5px 0',
  },
}
const useStyles = makeStyles(styles)

export type SignageDetailsModalType = {
  isEditing: boolean
  sign: SignType
  signInputData: any
  category: SignCategoryType
  title: string
}

type CostEstimateType = {
  optionLabel: string
  label: string
  value: string
}

export const SignageDetailsModal = ({
  isEditing,
  sign,
  signInputData,
  category,
  title,
}: SignageDetailsModalType) => {
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()
  const params = new URLSearchParams(location.search)
  const setSigns = useSignageStore((store) => store.setSigns)
  const { listingAddressCtx } = useRequestContext() as any
  const { signTypes, requestTypes, installationType } =
    useSignageContext() as any

  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [formData, setFormData] = useState<FormDataType>(
    signInputData?.formInputData || {},
  )
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [costEstimate, setCostEstimate] = useState<CostEstimateType>({
    optionLabel: '',
    label: '',
    value: '',
  })
  const [pickUpLocation, setPickUpLocation] = useState({})
  const [hasSignRemoval, setHasSignRemoval] = useState(false)

  const imageUrl =
    sign?.url ||
    sign?.formats?.small?.url ||
    sign?.formats?.medium?.url ||
    sign?.formats?.large?.url

  const buttonStyle = isEditing
    ? { primary: true, title: 'Edit' }
    : { secondary: true, title: 'Select' }

  const deliveryType = Object.values(formData).find(
    (option) => option.name === 'getSignage',
  )?.value

  const showCostEstimateField = !!deliveryType
  const isSaveButtonDisabled = !costEstimate?.value

  useEffect(() => {
    const isModalOpen =
      params.get('modal') == category.type &&
      params.get('id') == sign.signId.toString()
    setOpen(isModalOpen)
  }, [location, params])

  useEffect(() => {
    const getModalOptions = async () => {
      try {
        const signType = signTypes.find(
          (signType: Record<any, string>) => signType.type === category.type,
        )
        const requestType = requestTypes.find(
          (requestType: Record<any, string>) =>
            requestType.type === installationType,
        )
        const response = await getSignOptions(
          requestType?.id,
          signType?.id,
          listingAddressCtx.city,
        )
        if (response.success) {
          const formattedOptions: any = formatArrayOfOptions(response.data)
          setOptions(formattedOptions)
          setLoading(false)
        }
      } catch (err) {
        setLoading(false)
        setError(true)
      }
    }

    getModalOptions()
  }, [])

  useEffect(() => {
    const signRemoval = Object.values(formData).find(
      (option) => option.name === 'includeSignRemoval',
    )?.value
    setHasSignRemoval(signRemoval === 'yes')
  }, [formData])

  const handleClickOpen = () => {
    history.replace(
      `${location.pathname}?modal=${category.type}&id=${sign.signId}`,
    )
  }

  const handleClose = () => {
    // if (!isEditing) {
    //   setFormData({ ...formData, getSignage: {} })
    // }
    history.replace(`${location.pathname}`)
  }

  const handleSave = () => {
    const { validatedFields, hasError } = validateForm(options, formData)
    const formInputData: any = validatedFields

    //Used to rehydrate the modal when editing
    setFormData(formInputData)

    if (hasError) return

    //Used to send when submiting the request
    const requestFormattedData = {
      signId: sign.signId,
      deliveryType,
      type: category?.type,
      mediaUrl: imageUrl,
      options: {
        costEstimate: costEstimate?.value,
        ...formatFormDataIntoRequestData(validatedFields),
      },
    }

    // Used for presentation on review and submit page
    const presentationData = {
      signId: sign.signId,
      type: category?.type || '',
      typeDisplayName: category?.displayName || '',
      name: title,
      url: imageUrl,
      costEstimate,
      formDataOptions: formatFormDataIntoPresentationData(validatedFields),
    }

    setSigns(sign.signId, {
      signId: sign.signId,
      displayOrder: sign.displayOrder,
      templateType: sign.templateType,
      formInputData,
      requestFormattedData,
      presentationData,
    })
    handleClose()
  }

  const handleRemove = () => {
    setSigns(sign.signId, null)
    setFormData({})
    handleClose()
  }

  return (
    <>
      <Button small bordered {...buttonStyle} onClick={handleClickOpen} />
      <Dialog
        onClose={handleClose}
        aria-labelledby='dialog-title'
        open={open}
        fullWidth
        BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
        PaperProps={{ style: { borderRadius: '0', margin: 18, maxWidth: 375 } }}
      >
        <Grid className={classes.container}>
          <Grid container justify='space-between' alignItems='center'>
            <Text>
              {isEditing ? 'Edit ' : ''}
              {title}
            </Text>
            {!loading && (
              <Text
                type='medium'
                onClick={handleClose}
                style={{ cursor: 'pointer' }}
              >
                Cancel
              </Text>
            )}
          </Grid>
          <Text type='muted' style={{ marginTop: 10 }}>
            Design
          </Text>
          <Grid>
            <img
              src={imageUrl}
              alt='Section Thumbnail'
              className={classes.media}
            />
          </Grid>
          {error && <ErrorMsg />}
          {loading && <Loading heightVh='20vh' />}
          {showCostEstimateField && !loading && (
            <CostEstimateField
              deliveryType={deliveryType}
              signType={category.type}
              requestType={installationType}
              costEstimate={costEstimate}
              setCostEstimate={setCostEstimate}
              pickUpLocation={pickUpLocation}
              setPickUpLocation={setPickUpLocation}
              hasSignRemoval={hasSignRemoval}
            />
          )}
          {options.length > 0 && (
            <DynamicForm
              options={options}
              values={formData}
              setValues={setFormData}
              isEditing={isEditing}
            />
          )}
          {!loading && (
            <>
              <Grid
                container
                justify='center'
                style={{ margin: '40px 0 40px 0' }}
              >
                <Button
                  medium
                  bordered
                  primary
                  disabled={isSaveButtonDisabled}
                  title={error ? 'Close' : 'Save'}
                  clickHandler={error ? handleClose : handleSave}
                />
              </Grid>
              {isEditing && (
                <Grid
                  container
                  justify='center'
                  style={{ margin: '0 0 40px 0' }}
                >
                  <Button
                    medium
                    bordered
                    secondary
                    title='Remove'
                    noBorder
                    clickHandler={handleRemove}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Dialog>
    </>
  )
}
