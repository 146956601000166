import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import Text from '../../common/Text'
import { sumAndFormatCosts } from '../../../utils/utils'
import { getCostEstimate } from '../../../services/signageServices'
import { useRequestContext } from '../../../context/RequestContext'

export default function CostEstimateField({
  deliveryType = '',
  signType,
  requestType,
  costEstimate,
  setCostEstimate,
  setPickUpLocation,
  pickUpLocation,
  hasSignRemoval,
}) {
  const [loading, setLoading] = useState(true)
  const { listingAddressCtx } = useRequestContext()

  useEffect(() => {
    const getCost = async () => {
      const response = await getCostEstimate(
        listingAddressCtx.city,
        deliveryType,
        requestType,
        signType,
      )
      if (response.success) {
        const costEstimate = response.data.estimatedCost
        const vendorInformation = response.data.vendor
        setCostEstimate({
          optionLabel: 'Cost estimate',
          value: costEstimate,
        })
        if (deliveryType === 'PICK_UP') {
          setPickUpLocation({
            optionLabel: 'Pick up location',
            label: 'Pick up location',
            value: vendorInformation,
          })
        }

        if (hasSignRemoval && deliveryType === 'INSTALL') {
          const response = await getCostEstimate(
            listingAddressCtx.city,
            undefined,
            'removal',
          )
          if (response.success) {
            const formattedValue = sumAndFormatCosts(
              response.data.estimatedCost,
              costEstimate,
            )
            setCostEstimate({
              optionLabel: 'Cost estimate',
              value: formattedValue,
            })
          }
        }

        setLoading(false)
      }
    }
    if (deliveryType !== '') {
      getCost()
    }
  }, [deliveryType, hasSignRemoval])

  return (
    <Grid>
      <Text type="muted" style={{ marginBottom: 10 }}>
        Cost estimate
      </Text>
      {loading && (
        <Text type="muted" style={{ marginBottom: 12 }}>
          Calculating...
        </Text>
      )}
      {!loading && (
        <Text type="medium" style={{ marginBottom: 12 }}>
          {costEstimate.value}
        </Text>
      )}
      {deliveryType === 'PICK_UP' && (
        <>
          <Text type="muted" style={{ marginBottom: 10 }}>
            Pick up location
          </Text>
          <Text type="medium" style={{ marginBottom: 12 }}>
            {pickUpLocation?.value}
          </Text>
        </>
      )}
    </Grid>
  )
}
